<template>
  <div class="wrap" v-loading="initialling">
    <div @dblclick="disCopyRight" style="width: 15px; height: 15px; display: inline-block; cursor: pointer; top: 9999; position: absolute; top: 0px; left: 0px;"></div>
    <div class="left-nav">
      <img src="@/assets/logo/iUKY.png" alt="login-nav" />
    </div>
    <div class="content">
      <div class="content">
        <login-index class="animeta__animetad animeta__slideInLeft" v-if="loginStatus.status == 'index'" @changedLoginComponent="changedLoginComponent" @loginSuccess="handleLoginSuccess" :loginType="loginType"></login-index>
        <register class="animeta__animetad animeta__slideInRight" v-if="loginStatus.status == 'register'" @changedLoginComponent="changedLoginComponent"></register>
        <register-success class="animeta__animetad animeta__fadeIn" v-if="loginStatus.status == 'register-success'" :loginStatus="loginStatus" @changedLoginComponent="changedLoginComponent"></register-success>
        <forgot-password class="animeta__animetad animeta__slideInRight" v-if="loginStatus.status == 'forgot-password'" @changedLoginComponent="changedLoginComponent"></forgot-password>

        <!-- 修改密码 -->
        <UpdatePassword class="animeta__animetad animeta__slideInRight" :username="loginStatus.username" v-if="loginStatus.status == 'update-password'" @changedLoginComponent="changedLoginComponent" />
      </div>
      <div class="record" v-html="`${copyRight}&nbsp;&nbsp;&nbsp;&nbsp;version:${version}`"></div>
    </div>
  </div>
</template>

<script>
const packInfo = require('../../../package.json');

import { SystemInit } from '@/assets/js/system-init';
import LoginIndex from '@/components/login/Index';
import ForgotPassword from '@/components/login/ForgotPassword';
import UpdatePassword from '@/components/login/UpdatePassword';

import Register from '@/components/login/Register';
import RegisterSuccess from '@/components/login/RegisterSuccess';
import { funcMenuTree } from './router/index';
import { getLogin } from '@/service/login';
import { ReqUserGetUser } from '@/service/user';
import { copyRightMixin } from '@/util/mixins';

export default {
  name: 'login',
  components: {
    LoginIndex,
    Register,
    RegisterSuccess,
    ForgotPassword,
    UpdatePassword
  },
  mixins: [copyRightMixin],
  data() {
    return {
      version: packInfo.version,
      initialling: false,
      loginStatus: {
        status: 'index',
        register: false,
        message: ''
      },
      dftPath: undefined,
      rootEnd: false,
      loginType: 'pyk'
    };
  },
  created() {
    const token = this.$route.query.token;
    if (token && token != '') {
      this.reqGetLogin(token);
      return;
    }

    if (localStorage.getItem('status')) {
      this.loginStatus.status = localStorage.getItem('status');
    }
  },
  methods: {
    /* 登录成功的回调 */
    async handleLoginSuccess(lsRes) {
      this.initialling = true;
      this.initStore(lsRes);

      try {
        await SystemInit();
        this.setDefaultOrgId(lsRes.orgId);
        const permitRoutes = await funcMenuTree();

        if (permitRoutes != null) {
          this.$router.addRoutes(permitRoutes);
          this.$store.state.routerLoaded = true;
          this.getDefaultPath(permitRoutes[0].children);
          // console.log('default path:', this.dftPath)
          this.$router.push(this.dftPath);
        }
      } finally {
        setTimeout(() => {
          this.initialling = false;
        }, 3000);
      }
    },

    // 初始化登录缓存信息
    initStore(value) {
      // console.log("value", value);
      this.$store.state.user.currUser = value;
      // console.log("store", this.$store.state["user/currUser"]);
      this.$store.state.token = value.token;
      this.$store.state.user.industry = value.industry;
      this.$store.state.loginExpired = false;
      localStorage.setItem('nickName', value.nickName ? value.nickName : value.username);
      this.$store.commit('user/SET_INDUSTRY', value.industry ? value.industry : '');
    },

    /* 设置默认组织 */
    setDefaultOrgId(id) {
      const orgs = this.$store.state.user.organizations;
      const index = orgs.findIndex((i) => i.id == id);
      if (index > -1) {
        localStorage.setItem('orgId', id);
      } else if (orgs.length > 0) {
        localStorage.setItem('orgId', orgs[0].id);
      }
    },
    getDefaultPath(routes) {
      for (let p of routes) {
        if (p.meta.menu) {
          this.dftPath = p.path;
          // console.log('set default path:', this.dftPath)
        }
        if (p.meta.menu && p.children && p.children.length > 0) {
          this.getDefaultPath(p.children);
        } else {
          this.rootEnd = true;
        }

        if (this.dftPath && this.rootEnd) {
          return;
        }
      }
    },
    findDefaultPath(paths, children) {
      for (let i = 0; i < children.length; i++) {
        let child = children[i];
        if (child.meta && child.meta.menu) {
          paths.push(child.path);
        }
        let subChildren = child.children;
        if (subChildren && subChildren.length > 0) {
          this.findDefaultPath(paths, subChildren);
        }
        if (paths.length > 0) {
          break;
        }
      }
    },
    /* 切换登录页面组件 */
    changedLoginComponent(value) {
      this.loginStatus = value;
      localStorage.setItem('status', value.status);
    },
    reqGetLogin(token) {
      this.initialling = true;
      localStorage.removeItem('token');
      getLogin({ token: token })
        .then((res) => {
          if (res.success) {
            this.handleLoginSuccess(res.data);
          } else {
            this.initialling = false;
          }
        })
        .catch((err) => {
          this.doGetLoginError();
        });
    },
    doGetLoginError(message) {
      if (!message || message == '') {
        message = '授权凭证无效或已过期！';
      }
      this.initialling = false;
      this.$message({
        type: 'error',
        message: message
      });
    }
  }
};
</script>

<style scoped lang="scss">
.wrap {
  display: flex;
  height: 100%;
  width: 100%;
  background: #f0f3f7;

  .left-nav {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: auto;

    .back {
      display: block;
      font-size: 16px;
      margin: 32px 0 0 15px;

      a {
        color: #1890ff;
        text-decoration: none;
      }

      i {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }

    img {
      height: 100%;
      max-width: 600px;
    }
  }

  .content {
    position: relative;
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
